import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-15aeeab2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "pageList",
  class: "CxList"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ListEngine = _resolveComponent("ListEngine")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ListEngine, {
      pageList: _ctx.pageList,
      ref: "pageListRef",
      formatPageInfo: _ctx.formatPageInfo
    }, {
      queryParams: _withCtx(() => [
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('cx.queryParam.brandId')) + "：", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  ref: el=>_ctx.refMap.set('brandId',el),
                  modelValue: _ctx.pageList.queryParam.brandId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pageList.queryParam.brandId) = $event)),
                  placeholder: _ctx.$t('cx.queryParam.brandId_placeholder'),
                  clearable: "",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'brandId'}), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('cx.queryParam.name')) + "：", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[1] || (_cache[1] = e => _ctx.pageList.queryParam.name = _ctx.valid(e)),
                  placeholder: _ctx.$t('cx.queryParam.name_placeholder'),
                  modelValue: _ctx.pageList.queryParam.name,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pageList.queryParam.name) = $event)),
                  clearable: ""
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      extendSlot: _withCtx(() => []),
      tbCols: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "F_NAME",
          label: _ctx.$t('cx.columns.F_NAME')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "F_COUNTRY_ID",
          label: "上市国家"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_BRAND_ID",
          label: _ctx.$t('cx.columns.F_BRAND_ID')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "SYS_LAST_USER",
          label: "操作人"
        }),
        _createVNode(_component_el_table_column, {
          prop: "SYS_LAST_TIME",
          label: "操作时间"
        })
      ]),
      _: 1
    }, 8, ["pageList", "formatPageInfo"])
  ]))
}